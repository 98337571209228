import Config from '../config';
import createSurveyComponent from './survey_component';
import User_Identification from 'component/user_identification';
import Session_Recorder from 'component/session_recorder';
import rage from 'component/rage';
import Lib from 'lib/lib';

import {
    addGlobalEventListener,
    isGlobalEventListenerAdded,
    removeGlobalEventListener
} from './global_event';

import {
    isValidateCondition,
    isValidateDevice,
    isValidateFrequency,
    isValidatePosition,
    isValidateSurveySize,
    isValidateTriggerType,
    isValidateTriggerDelay,
    isValidateTriggerAction,
    isValidateUserSegment,
    isValidateFormat,
    isValidateBackgroundColour
} from './validate';

import {isDesktop, isMobile, isTablet} from './device';
import {isUsed as isUsedFigerPrint, record as recordFingerPrint} from './finger_print';
import {isValidQuerySelector} from './util';
import styles from './main.module.scss';

let local_configs = [];
let local_open_survey = [];
let local_initial_url = window.location.href;
let local_session_data = null;
let local_is_demo_mode = false;
let local_delay_timeout = null;
let local_close_timeout = null;
let local_rage = null;

// init survey module
export function init(survey_config) {
    if (typeof window.__USERBACK_SURVEY_EVENTS === 'undefined') {
        window.__USERBACK_SURVEY_EVENTS = {};
    }

    // check if survey is already initialized in the page
    // TODO: this does not seem to be correct
    if (!window.Userback.init) {
        return;
    }

    // check if survey config is available
    if (!survey_config || !Array.isArray(survey_config) || survey_config.length === 0) {
        return;
    }

    // Clean up timer
    if (local_delay_timeout) {
        clearTimeout(local_delay_timeout);
    }

    if (local_close_timeout) {
        clearTimeout(local_close_timeout);
    }

    if (window.Userback.demo_mode) {
        local_is_demo_mode = true;
    }

    // set session data
    local_session_data = window.Userback.session_data;
    local_initial_url = window.location.href;
    local_rage = rage();

    loadConfig(survey_config);
    trigger(true);

    if (!isGlobalEventListenerAdded('popstate', handlePopState)) {
        addGlobalEventListener('popstate', handlePopState);
    }

    if (!isGlobalEventListenerAdded('message', handleMessageListener)) {
        addGlobalEventListener('message', handleMessageListener);
    }

    if (!isGlobalEventListenerAdded('click', handleElementClick)) {
        // Add global click event
        addGlobalEventListener('click', handleElementClick, true);
    }

    local_rage.on(handleRageClick);
}

// open survey
export function trigger(is_page_load = false) {
    if (local_is_demo_mode) {
        if (local_configs.length > 0) {
            open(local_configs[0]);
        }

        return;
    }

    // loop through each survey config
    const filtered_configs = getConfigsAfterConditionCheck();

    // only allow time trigger when page is loaded
    const survey_to_trigger = getMatchedSurvey(filtered_configs.filter((config) => {
        return config.trigger_type === 'time';
    }));

    if (!survey_to_trigger) {
        return;
    }

    const {trigger_delay} = survey_to_trigger;

    if (is_page_load) {
        local_delay_timeout = setTimeout(() => {
            open(survey_to_trigger);
        }, trigger_delay * 1000);
    } else {
        open(survey_to_trigger);
    }
}

function getConfigsAfterConditionCheck() {
    return local_configs.filter((config) => {
        // check survey match device conditions
        if (!checkDevice(config)) {
            return false;
        }

        // check survey match frequency conditions
        if (!checkFrequency(config)) {
            return false;
        }

        // check survey match page conditions
        if (!checkPages(config)) {
            return false;
        }

        // check user segment conditions
        if (!checkUserSegment(config)) {
            return false;
        }

        return true;
    });
}

function handleRageClick() {
    if (!local_rage) {
        return;
    }

    let filtered_configs = getConfigsAfterConditionCheck();

    // filter the configs which trigger type is rage, and there is an action
    filtered_configs = filtered_configs.filter((config) => {
        return config.trigger_type === 'rage';
    });

    // check if the rage click is matched with the queryselector result of trigger action
    const survey_to_trigger = getMatchedSurvey(filtered_configs);

    if (survey_to_trigger) {
        open(survey_to_trigger);
    }
}

function handleElementClick(e) {
    let filtered_configs = getConfigsAfterConditionCheck();
    // filter the configs which trigger type is element, and there is an action
    filtered_configs = filtered_configs.filter((config) => {
        return config.trigger_type === 'element' && config.trigger_action;
    });

    // check if the e.target matched with the queryselector result of trigger action
    filtered_configs.forEach((config) => {
        const {trigger_action} = config;
        if (!isValidQuerySelector(trigger_action)) {
            return;
        }

        if (e.target.matches(trigger_action) || e.target.closest(trigger_action)) {
            open(config);
        }
    });
}

// close all survey
export function closeAll() {
    // loop through each local_open_survey
    for (const open_survey of local_open_survey) {
        close(open_survey.key);
    }

    // clean up local_open_survey
    local_open_survey = [];
}

export function close(key) {
    // Search key in local_open_survey
    const survey_open_by_key = local_open_survey.filter((survey) => {
        return survey.key === key;
    });

    if (survey_open_by_key.length === 0) {
        return;
    }

    const survey_to_close = survey_open_by_key[0];
    survey_to_close.dom.remove();
    // remove survey from local_open_survey
    local_open_survey = local_open_survey.filter((survey) => {
        return survey.key !== key;
    });

    // Create a survey close
    if (local_is_demo_mode) {
        // When in demo mode, we need to send event to notify survey close
        const close_event = new CustomEvent('userback:survey:close', {key});

        document.dispatchEvent(close_event);
    }
}

export function api() {
    return {
        init: function() {
            init();
        },

        openSurvey: function(key) {
            // Search key in configs
            const configs = local_configs.filter((config) => {
                if (config.key === key) {
                    return true;
                }
            });

            if (configs.length === 0) {
                console.warn('Survey not found');
                return;
            }

            open({key, ...configs[0]});
        },

        closeSurvey: function() {
            closeAll();
        },

        destroy: function() {
            closeAll();

            removeGlobalEventListener('popstate', handlePopState);
            removeGlobalEventListener('message', handleMessageListener);
            removeGlobalEventListener('click', handleElementClick);
            if (local_rage) {
                local_rage.stop();
            }
        }
    }
}

// open survey
function open(config) {
    const {key} = config;

    // check whether current key is aleady open
    const survey_open_by_key = local_open_survey.filter((survey) => {
        return survey.key === key;
    });

    const is_current_key_open = survey_open_by_key.length > 0;
    if (is_current_key_open) {
        return;
    }
    // Make record of survey open
    recordFingerPrint(key, 'open');

    const { format, position, size, has_background_colour, background_colour } = config;

    const container = document.body;

    // open survey
    // create iframe on dom based on config
    const new_survey = createSurveyComponent(
        key,
        `${Config.survey_url.replace(/\/$/, '')}/${key}/e`,
        format,
        position,
        size,
        has_background_colour,
        background_colour,
        handleClose
    );

    const iframe_uid = 'iframe-' + new Date().getTime() + '-' + Math.random().toString(36).substring(2, 11);

    local_open_survey.push({dom: new_survey, key: key, uid: iframe_uid});

    container.appendChild(new_survey);
}

// close survey
function handleClose(key) {
    if (!local_is_demo_mode) {
        recordFingerPrint(key, 'close');
    }

    close(key);
}

// load survey config
function loadConfig(data) {
    // Set configs
    local_configs = validateSurveyConfig(data);
}

// validate survey config
function validateSurveyConfig(data) {
    if (!Array.isArray(data)) {
        return [];
    }

    const validate_data = [];

    const default_config = {
        format: 'paged',
        device: 'desktop,mobile,tablet',
        position: 'center',
        size: 'medium',
        trigger_type: 'time',
        trigger_delay: 0,
        trigger_action: '',
        user_segment: '',
        has_page_condition: false,
        has_background_colour: false,
        background_colour: '',
        condition: [],
        frequency: 'oncesubmit'
    };

    // Loop through each entry in the array and validate its properties
    for (const entry of data) {
        const new_entry = {...default_config};

        if (typeof entry === 'object') {
            if (entry.key) {
                new_entry.key = entry.key;
                new_entry.id = entry.id;

                const {
                    format,
                    device,
                    position,
                    frequency,
                    has_page_condition,
                    condition,
                    size,
                    trigger_type,
                    trigger_delay,
                    trigger_action,
                    user_segment,
                    has_background_colour,
                    background_colour
                } = entry;

                if (isValidateFormat(format)) {
                    new_entry.format = format;
                }

                if (isValidateDevice(device)) {
                    new_entry.device = device;
                }

                if (isValidatePosition(position)) {
                    new_entry.position = position;
                }

                if (isValidateSurveySize(size)) {
                    if (new_entry.position === 'top' || new_entry.position === 'bottom') {
                        new_entry.size = 'large';
                    } else {
                        new_entry.size = size;
                    }
                }

                if (isValidateFrequency(frequency)) {
                    new_entry.frequency = frequency;
                }

                if (isValidateTriggerType(trigger_type)) {
                    new_entry.trigger_type = trigger_type;
                }

                if (isValidateTriggerDelay(trigger_delay)) {
                    new_entry.trigger_delay = trigger_delay;
                }

                if (isValidateTriggerAction(trigger_action)) {
                    new_entry.trigger_action = trigger_action;
                }

                if (typeof has_page_condition !== 'boolean') {
                    new_entry.has_page_condition = !!has_page_condition;
                } else {
                    new_entry.has_page_condition = has_page_condition;
                }

                if (isValidateCondition(condition)) {
                    new_entry.condition = condition;
                }

                if (isValidateUserSegment(user_segment)) {
                    new_entry.user_segment = user_segment;
                }

                if (typeof has_background_colour === 'boolean') {
                    new_entry.has_background_colour = entry.has_background_colour;
                }

                if (isValidateBackgroundColour(background_colour)) {
                    new_entry.background_colour = background_colour;
                }
            }
        }

        validate_data.push(new_entry);
    }

    return validate_data;
}

// check survey match device conditions
function checkDevice(config) {
    if (!config) {
        return false;
    }

    // Get device from config
    const { device } = config;

    if (!device) {
        return false;
    }

    // Get current device
    const devices = device.split(',');

    // Loop through each device and validate its value
    for (const device of devices) {
        if (device === 'desktop' && isDesktop()) {
            return true;
        }

        if (device === 'mobile' && isMobile()) {
            return true;
        }

        if (device === 'tablet' && isTablet()) {
            return true;
        }
    }

    return false;
}

// check survey match frequency conditions
function checkFrequency(config) {
    const {key} = config;

    const {frequency} = config;
    const frequency_parts = frequency.split('_');

    let recur_days = 0;
    if (frequency_parts[1] && !isNaN(frequency_parts[1])) {
        recur_days = parseInt(frequency_parts[1], 10);
    }

    const day_timestamp = 24 * 60 * 60 * 1000;

    if (frequency_parts[0] === 'once') {
        // Check second part is a number
        if (isUsedFigerPrint(key, 'once', recur_days * day_timestamp)) {
            return false;
        }
    } else if (frequency_parts[0] === 'oncesubmit') {
        if (isUsedFigerPrint(key, 'submit', recur_days * day_timestamp)) {
            return false;
        }
    } else if (frequency_parts[0] === 'close') {
        if (isUsedFigerPrint(key, 'close', recur_days * day_timestamp)) {
            return false;
        }
    }

    return true;
}

// check user segment conditions
function checkUserSegment(config) {
    const {user_segment} = config;
    if (!user_segment) {
        return true;
    }

    // feedback target only allows one segment to be used in target settings
    const segment_match = User_Identification.matchSegments(user_segment, 'or');

    return segment_match;
}

// check survey match page conditions
function checkPages(config) {
    if (!config) {
        return false;
    }

    // Pass if survey is for all pages
    if (!config.has_page_condition) {
        return true;
    }

    // Get condition from config
    const { condition } = config;

    if (!condition) {
        return false;
    }

    if (!checkUrlCondition(condition)) {
        return false;
    }

    return true;
}

function checkUrlCondition(condition) {
    if (condition.operator === 'and') {
        for (const subCondition of condition.conditions) {
            if (!matchSubCondition(subCondition)) {
                return false;
            }
        }
        return true;
    } else if (condition.operator === 'or') {
        for (const subCondition of condition.conditions) {
            if (matchSubCondition(subCondition)) {
                return true;
            }
        }
        return false;
    }
    return false;
}

function getMatchedSurvey(configs) {
    const matched_surveys = [];
    configs.forEach((config) => {
        let matching_weight = 0;
        if (config.user_segment) {
            matching_weight += 10;
        }

        if (config.has_page_condition && config.condition) {
            matching_weight += 20;
        }

        matched_surveys.push({
            config,
            matching_weight
        });
    });

    let survey_to_trigger = null;
    if (matched_surveys.length === 1) {
        survey_to_trigger = matched_surveys[0].config;
    } else if (matched_surveys.length > 1) {
        matched_surveys.sort((a, b) => {
            if (a.matching_weight === b.matching_weight) {
                return b.config.id - a.config.id;
            } else {
                return b.matching_weight - a.matching_weight;
            }
        });

        survey_to_trigger = matched_surveys[0].config;
    }

    return survey_to_trigger;
};

function matchSubCondition(condition) {
    const window_location = window.location.origin + window.location.pathname;

    switch (condition.type) {
        case 'exact_url_match':
            return window_location === condition.value;
        case 'url_start':
            return window_location.indexOf(condition.value) === 0;
        case 'url_end':
            return window_location.endsWith(condition.value);
        case 'url_contains':
            return window_location.indexOf(condition.value) !== -1;
        case 'url_contains_not':
            return window_location.indexOf(condition.value) === -1;
        case 'url_regex':
            var regexp = new RegExp(condition.value);
            return regexp.test(window_location) ? true : false;
        default:
            return false;
    }
}

function postMessageToSurvey(message, data, survey_key, message_id) {
    // look up local_open_survey by survey_key
    const survey_open_by_key = local_open_survey.filter((survey) => {
        return survey.key === survey_key;
    });

    const is_current_key_open = survey_open_by_key.length > 0;

    if (is_current_key_open) {
        const iframe = survey_open_by_key[0].dom.querySelector('iframe');
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({message, data, messageId: message_id}, '*');
        }
    }
}

function handleMessageListener(event) {
    const message_data = event.data;
    const survey_key = message_data.surveyKey;

    // Get the current open survey
    const survey_open_by_key = local_open_survey.filter((survey) => {
        return survey.key === survey_key;
    });

    if (survey_open_by_key.length === 0) {
        return;
    }

    const current_open_survey = survey_open_by_key[0];

    switch(message_data.message) {
        case 'survey/init':
            postMessageToSurvey('survey/init', {uid: current_open_survey?.uid}, survey_key);
            postMessageToSurvey('survey/sessionData', {
                sessionId: Session_Recorder.getSessionId(),
                identification: User_Identification.getIdentification() || '',
                page: local_session_data?.page || window.location.href,
                uaData: JSON.stringify(Lib.getUAData()),
                userAgent: window.navigator.userAgent,
                windowX: local_session_data?.window_x || window.innerWidth,
                windowY: local_session_data?.window_y || window.innerHeight,
                resolutionX: local_session_data?.resolution_x || window.screen.width,
                resolutionY: local_session_data?.resolution_y || window.screen.height,
                dpi: local_session_data?.dpi || window.devicePixelRatio || 0,
                pathname: window.location.pathname
            }, survey_key);
            break;
        case 'survey/fetchSessionData/request':
            postMessageToSurvey('survey/fetchSessionData/response', {
                sessionId: Session_Recorder.getSessionId(),
                page: local_session_data?.page || window.location.href,
                uaData: JSON.stringify(Lib.getUAData()),
                userAgent: window.navigator.userAgent,
                windowX: local_session_data?.window_x || window.innerWidth,
                windowY: local_session_data?.window_y || window.innerHeight,
                resolutionX: local_session_data?.resolution_x || window.screen.width,
                resolutionY: local_session_data?.resolution_y || window.screen.height,
                dpi: local_session_data?.dpi || window.devicePixelRatio || 0,
                pathname: window.location.pathname,
            }, survey_key, message_data.messageId);
            break;
        case 'survey/submit':
            recordFingerPrint(message_data.key, 'submit');

            // close survey after 3 seconds
            local_close_timeout = setTimeout(() => {
                close(survey_key);
            }, 3000);

            break;
        case 'general/height':
            if (current_open_survey && current_open_survey.dom) {
                // check if the message is for the current open survey
                if (message_data.uid !== current_open_survey.uid) return;

                // set iframe height
                const local_survey_dom = current_open_survey.dom;
                const iframe = local_survey_dom.querySelector('iframe');

                var height_old = parseInt(iframe.style.height.replace('px', ''), 10);
                var height_new = message_data.height;

                // do not update the height if it only changes a tiny little bit
                if (Math.abs(height_old - height_new) <= 10) {
                    return;
                }

                iframe.style.height = `${height_new}px`;

                let container;
                if (local_survey_dom.classList.contains(styles.overlay)) {
                    container = local_survey_dom.querySelector(`.${styles.container}`);
                } else {
                    container = local_survey_dom;
                }

                if (container && !container.classList.contains(styles.active)) {
                    // add a small delay so that active class is added after all the height changes
                    setTimeout(() => {
                        container.classList.add(styles.active);
                    }, 100);
                }
            }
            break;
        default:

            break;
    }
}

function handlePopState() {
    if (window.location.href === local_initial_url) {
        return;
    }

    if (local_delay_timeout) {
        clearTimeout(local_delay_timeout);
    }

    if (local_close_timeout) {
        clearTimeout(local_close_timeout);
    }

    local_initial_url = window.location.href;
    if (local_open_survey && local_open_survey.length) {
        // Only open when current open survey is still valid
        if (local_configs.length > 0) {
            const filtered_configs = local_configs.filter((config) => {
                // check survey match page conditions
                if (!checkPages(config)) {
                    return false;
                }

                if (config.trigger_type !== 'time') {
                    return false;
                }

                return true;
            });

            const survey_matched_open = filtered_configs.filter((config) => {
                const survey_open_by_key = local_open_survey.filter((survey) => {
                    return survey.key === config.key;
                });

                if (survey_open_by_key.length > 0) {
                    return true;
                }

                return false;
            });

            if (survey_matched_open.length > 0) {
                return;
            } else {
                closeAll();
                const survey_to_trigger = getMatchedSurvey(filtered_configs);
                if (survey_to_trigger) {
                    open(survey_to_trigger);
                }
            }
        } else {
            closeAll();
        }
    } else {
        trigger();
    }
}
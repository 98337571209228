import $ from 'jquery';

const Scripts = {
    html2canvas: 'https://static.userback.io/widget/slocal.min.js',
    rrweb: 'https://static.userback.io/widget/record-1.1.3.min.js',

    // TODO: use webpack to do the lazy load
    lazyLoad: function(scripts, callback) {
        let load_count = 0;

        scripts.forEach(function(script_url) {
            $.ajax({
                url: script_url,
                cache: true,
                dataType: 'script',
                context: this,
                success: function() {
                    load_count++;

                    if (load_count == scripts.length && typeof callback !== 'undefined') {
                        callback();
                    }
                }
            });
        });
    }
};

export default Scripts;
import { hexToRgba } from './util';
import styles from './main.module.scss';

const createSurveyComponent = (key, path, format, position, size, has_background_colour, background_colour, onCloseCallback) => {
    const container = document.createElement('ubdiv');
    container.classList.add(styles.container);

    if (format === 'pageless') {
        container.classList.add(styles['container-pageless']);
    } else {
        container.classList.add(styles[position]);
    }

    const close = document.createElement('ubdiv');
    close.classList.add(styles.close);

    // TODO: This will be replaced after Svg_Icons can be importable
    close.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>';

    const iframe = document.createElement('iframe');
    iframe.classList.add(styles.iframe);

    if (format === 'paged') {
        iframe.classList.add(styles[size]);
    } else {
        iframe.classList.add(styles.large);
    }

    // TODO: This will be replaced by size setting
    iframe.src = path;

    close.addEventListener('click', () => {
        onCloseCallback(key);
    });

    container.appendChild(close);
    container.appendChild(iframe);

    if (has_background_colour) {
        const overlay = document.createElement('ubdiv');
        overlay.classList.add(styles.overlay);
        // Add background colour
        const [bg_colour, bg_opacity] = background_colour.split(',');
        const rgba = hexToRgba(bg_colour, bg_opacity/100);
        overlay.style.backgroundColor = rgba;

        overlay.appendChild(container);

        return overlay;
    }

    return container;
}

export default createSurveyComponent;
